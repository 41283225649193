<template>
  <div class="mb-28 mt-5.75 <lg:mb-12">
    <div class="flex flex-row justify-center mb-12.25">
      <!-- <div class="tabs-name" @click="showTab()" :class="{ isActive: !content }"> -->
      <div
        class="tabs-name <sm:text-sm"
        @click="showTab()"
        :class="{ isActive: tab1 }"
      >
        <svg
          class="mr-2"
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.09119 7.39857C8.01346 7.39857 9.57178 5.84025 9.57178 3.91797C9.57178 1.99569 8.01346 0.437378 6.09119 0.437378C4.16891 0.437378 2.6106 1.99569 2.6106 3.91797C2.6106 5.84025 4.16891 7.39857 6.09119 7.39857ZM13.0522 9.13885C14.4939 9.13885 15.6626 7.97011 15.6626 6.5284C15.6626 5.08669 14.4939 3.91796 13.0522 3.91796C11.6105 3.91796 10.4417 5.08669 10.4417 6.5284C10.4417 7.97011 11.6105 9.13885 13.0522 9.13885ZM17.403 14.3598C17.403 14.8404 17.0134 15.23 16.5328 15.23H12.1821C12.1821 15.7105 11.7925 16.1001 11.3119 16.1001H0.870149C0.389579 16.1001 0 15.7105 0 15.23C0.00332771 12.77 1.48599 10.5536 3.75837 9.61152C6.03075 8.66945 8.64674 9.18671 10.3896 10.9227C11.7017 9.90735 13.4771 9.7266 14.9669 10.4567C16.4567 11.1868 17.4016 12.7007 17.403 14.3598Z"
            fill="currentColor"
          />
        </svg>
        Users
      </div>
      <!-- <div class="tabs-name" @click="showTab()" :class="{ isActive: content }"> -->
      <div
        class="tabs-name <sm:text-sm"
        @click="showTab1()"
        :class="{ isActive: tab2 }"
      >
        <svg
          class="mr-2"
          width="30"
          height="28"
          viewBox="0 0 30 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.3994 1.0743L27.7121 4.7231C27.9399 4.78773 28.1528 4.89704 28.3387 5.04479C28.5245 5.19253 28.6796 5.37579 28.7951 5.58406C28.9105 5.79233 28.9841 6.02152 29.0114 6.25849C29.0388 6.49546 29.0196 6.73556 28.9547 6.965L23.1956 26.6903C23.0613 27.1512 22.751 27.5395 22.3329 27.7701C21.9149 28.0006 21.4231 28.0545 20.9656 27.9199L8.65299 24.2711C8.19677 24.1346 7.81282 23.8214 7.58523 23.4001C7.35764 22.9788 7.30496 22.4838 7.43874 22.0235L13.1695 2.29819C13.2353 2.06965 13.3453 1.85651 13.4932 1.67107C13.6412 1.48563 13.824 1.33157 14.0313 1.21777C14.2387 1.10398 14.4664 1.03271 14.7012 1.00809C14.936 0.983462 15.1733 1.00596 15.3994 1.0743ZM10.1225 17.145L23.4225 21.0855L27.7461 6.25582L14.4518 2.31535L10.1225 17.145ZM7.5748 18.6949L6.47975 22.4524C6.34367 22.9521 6.4093 23.4859 6.66233 23.937C6.91535 24.3881 7.33512 24.72 7.8301 24.8602L10.6331 25.6894L4.00013 26.3586C3.52564 26.4049 3.05227 26.2601 2.68337 25.9558C2.31446 25.6514 2.08 25.2123 2.03125 24.7343L0.0113324 4.27701C-0.013759 4.04021 0.00795512 3.80076 0.0752622 3.57248C0.142569 3.3442 0.254106 3.13164 0.403431 2.94709C0.552756 2.76254 0.736893 2.60966 0.945207 2.4973C1.15352 2.38494 1.38188 2.31532 1.61706 2.29247L12.7212 1.17725C12.6742 1.26263 12.6361 1.35272 12.6077 1.44605L12.2445 2.69281L1.20287 3.80232L2.72347 19.1868L7.5748 18.6949Z"
            fill="currentColor"
          />
        </svg>

        Collections
      </div>
    </div>
    <div v-if="tab1">
      <div class="flex items-center justify-center">
        <img
          alt="Avatars"
          class="w-65 h-20.25"
          src="/images/home/avatars.webp"
        />
      </div>
      <div
        class="flex flex-row justify-center mt-18 <lg:flex-col <lg:items-center"
      >
        <div
          v-for="i in users"
          :key="i.id"
          class="content-item text-lg max-w-68 font-500 text-center flex flex-col items-center justify-center mr-27.75 <xl:mr-18 <lg:mr-0 <lg:mb-7.5"
        >
          <div class="flex items-center h-16.5 justify-center">
            <img :alt="i.title" :src="i.img" />
          </div>
          <div class="max-h-27 min-h-27">
            <div class="text-white">{{ i.title }}</div>
            <div class="text-[#979797]">{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab2">
      <div class="flex items-center justify-center">
        <img
          alt="Avatars"
          class="w-71.5 h-20.25"
          src="/images/home/avatars-2.webp"
        />
      </div>
      <div
        class="flex flex-row justify-center mt-18 <lg:flex-col <lg:items-center"
      >
        <div
          v-for="i in collections"
          :key="i.id"
          class="content-item text-lg max-w-68 font-500 text-center flex flex-col items-center justify-center mr-27.75 <xl:mr-18 <lg:mr-0 <lg:mb-7.5"
        >
          <div class="flex items-center h-16.5 justify-center">
            <img :alt="i.title" :src="i.img" />
          </div>
          <div class="max-h-27 min-h-27">
            <div class="text-white">{{ i.title }}</div>
            <div class="text-[#979797]">{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full h-0.25 bg-[#2c2c2c]" />
</template>

<script lang="ts" setup>
import { collections, users } from '~/assets/data/other/slider-collection.json'

const tab1 = ref(true)
const tab2 = ref(false)

const content = ref(false)
const showTab = () => {
  if (tab2.value) {
    tab1.value = !tab1.value
    tab2.value = !tab2.value
  }
}

const showTab1 = () => {
  if (tab1.value) {
    tab2.value = !tab2.value
    tab1.value = !tab1.value
  }
}
</script>

<style scoped>
.isActive {
  background: linear-gradient(180deg, #292929 0%, rgba(41, 41, 41, 0) 100%);
  color: #b696ff !important;
}

.content-item {
  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.tabs-name {
  @apply text-[#F0F0F0] w-127 h-19.75 flex items-center justify-center uppercase rounded-2xl font-500 cursor-pointer;
}
</style>
